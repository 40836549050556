
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of groups</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-6 col-lg-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportSelected()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option :value="null"> All </option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                d-flex align-items-center">
                  {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                </div>
              </template>
              <template v-slot:head(check)>
                <div style="width:3.5rem;" class="form-check text-center">
                  <label class="form-check-label me-2" for="tableSRadioHead">
                    <input @change="selectAllRows($event)" type="checkbox"
                      class="form-check-input" id="tableSRadioHead" />
                  </label>
                </div>
              </template>
              <template v-slot:cell(check)="data">
                <div style="width:3.5rem;" class="form-check text-center me-auto">
                  <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                    :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(logo)="data">
                <div class="cursor-pointer">
                  <img @click="showProfileImg(data.item.logo)" class="avatar rounded-circle" :src="absoluteUrl(data.item.logo)" />
                </div>
              </template>
              <template v-slot:cell(friendly_id)="data">
                <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.friendly_id }}
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.name }}
                </div>
              </template>
              <template v-slot:cell(head)="data">
                <div style="min-width:15rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <h6 class="mb-1">{{ data.item.group_head_first_name }} {{ data.item.group_head_last_name }}</h6>
                  <span>{{ data.item.group_head_email }}</span>
                </div>
              </template>
              <template v-slot:cell(members_count)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.members_count }}</span>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="width:7rem;" class="cursor-pointer">
                  {{$filters.date(data.item.created_at, 1) }} 
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';
import { commonSortingMixin } from '@/mixins/commonSortingMixin';
import { commonPaginationMixin } from '@/mixins/commonPaginationMixin';
import { commonSelectRowMixin } from '@/mixins/commonSelectRowMixin';

export default {
  name: "group-list",
  components:{
    IsLoading
  },
  mixins: [
    commonSortingMixin,
    commonPaginationMixin,
    commonSelectRowMixin
  ],
  data() {
    return {
      isLoading: false,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: "pending", label: "Pending" },
        { value: "approved", label: "Approved" }
      ],
      columns: [
        {
          key: "check",
        },
        {
            key: "logo",
            label: "Logo",
        },
        {
            key: "friendly_id",
            label: "Code",
        },
        {
            key: "name",
            label: "Name",
            sortable: true
        },
        {
            key: "head",
            label: "Group head",
        },
        {
            key: "members_count",
            label: "Total Members",
            sortable: true
        },
        {
            key: "created_at",
            label: "Created at",
            sortable: true
        },
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "desc"
      },
    }
  },
  computed: {
    pageData(){
      return this.$store.state.group.pageData
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/groups/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("group/fetchGroups", payload)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.initializeAll();
          }
        })
    },
    exportSelected() {
      if(!this.selectedItems.length){
        this.alertError("Select the groups you'd like to export."); return;
      }
      const json = this.pageData.data
                    .filter(cd => this.selectedItems.includes(cd.id))
                    .map(item => {
                      return Object.keys(item).reduce((acc, key) => {
                        let formattedKey = key.replace(/_/g, " ") // Optional: Format key names
                                        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize words

                        if(key.includes("_at")){
                          acc[formattedKey] = this.$filters.date(item[key], 1)
                        }else if(key == 'friendly_id'){
                          acc['group_number'] = item[key]
                        }else if(['group_head_image','logo','group_head_id_card'].includes(key)){
                          acc[formattedKey] = this.absoluteUrl(item[key])
                        }else{
                          acc[formattedKey] = item[key];
                        }
                        return acc;
                      }, {});
                    })
        const csvString = this.jsonToCSV(json)
        this.downloadCSV(csvString, "groups.csv")
    },
    initializeAll(){
      this.selectedItems = [];
      this.lastCheckedIndex = null
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

